<template>
  <div class="about">
    <img class="imgLogo" src="images/ZLI-logo.jpg" />
    <h1>The Zhodani Language & Cultural Institute (ZLI) presents:</h1>
    <br />
    <h1>Zdetl Lessons</h1>
    <img class="imgIntro" src="images/AZ_138-2021.png" />
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.imgLogo {
  width: 400px;
  height: 200px;
}

.imgIntro {
  width: 800px;
  height: 266px;
}
</style>
